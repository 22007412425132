(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:discussionView
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <discussion-view></discussion-view>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('discussionView', discussionView);

  function discussionView() {
    return {
      restrict: 'EA',
      scope: {
        id: '=',
        resource: '=',
        view: '=',
        tags: '=',
        groupId: '=',
        isTeacher: '='
      },
      templateUrl: 'components/discussion-view/discussion-view-directive.tpl.html',
      replace: false,
      controllerAs: 'discussionView',
      controller: 'DiscussionViewCtrl'
    };
  }
}());
